import { css, keyframes } from "@emotion/react";
import { Field, Formik } from "formik";
import type { FunctionComponent } from "react";
import { useState } from "react";

import Anchor from "../Anchor";
import Button from "../Button";
import CheckBox from "../Form/CheckBox";
import ErrorLabel from "../Form/ErrorLabel";
import RadioOrCheckBoxLabel from "../Form/RadioOrCheckboxLabel";
import FormRow from "../FormRow";
import Select from "../Select";
import Text from "../Text";
import TextArea from "../TextArea";
import TextInput from "../TextInput";
import type { MarketoFormResponse } from "./types";
import { getFormikFromMarketo, submitMarketoForm } from "./utils";

interface Props {
  form: MarketoFormResponse;
  onSuccess: (form: any) => void;
  passedValues?: any;
  submitID?: string;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

/**
 * The main Marketo contact form, rendered by React and Formik.
 */
const MarketoForm: FunctionComponent<Props> = ({
  form,
  onSuccess,
  passedValues,
  submitID,
}) => {
  const {
    fields,
    formId,
    getDependentOptions,
    getVisibility,
    initialValues,
    validationSchema,
    hiddenFields,
  } = getFormikFromMarketo(form, passedValues);

  /**
   * Error to display in the edge case that a form validates,
   * but still gets rejected by Marketo. The Yup validation
   * schema should catch any issues before we open an
   * interaction with Marketo, but in the event that
   * something slips through, we need a way to inform the
   * user and offer an alternative course of action.
   */
  const [submissionError, setSubmissionError] = useState("");

  return (
    <>
      <Formik
        initialValues={{ ...initialValues, ...hiddenFields }}
        onSubmit={async (values, actions) => {
          // Clear any existing submission error
          setSubmissionError("");
          let response: any;

          try {
            // Marketo does not work locally and it ain't our fault
            if (window.location.hostname !== "localhost") {
              response = await submitMarketoForm(formId!, values);
            }
            onSuccess(response);
          } catch (error) {
            actions.setSubmitting(false);
            setSubmissionError(
              "We're sorry-- Something went wrong and your information could not be sent. Please contact customer support at +1 800 709 1208.",
            );
            console.warn("Error", error);
          }
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(props) => {
          const cityOptions = getDependentOptions(
            fields,
            props.values.Country,
            "City",
          );
          const stateOptions = getDependentOptions(
            fields,
            props.values.Country,
            "State",
          );
          const secondaryAreaOptions = getDependentOptions(
            fields,
            props.values.Primary_Area_of_Research__c,
            "tempAreaofResearch",
          );
          const otherResearchAreaIsVisible = getVisibility(
            fields,
            props.values.Primary_Area_of_Research__c,
            "Other_Primary_Area_of_Research__c",
          );
          const postalCodeIsVisible = getVisibility(
            fields,
            props.values.Country,
            "PostalCode",
          );

          /**
           * Need help debugging the spiralling vortex
           * of darkness and confusion that is Marketo?
           *
           * Add id={`mktoForm_${formId}`} to the root
           * form element to see Marketo error output
           * directly in the DOM. It shows up at the
           * bottom of the form.
           */
          return (
            <form
              css={css`
                animation: ${fadeIn} 0.5s;
              `}
              id={`marketo_form_${formId?.toString()}`}
              noValidate={true}
              onReset={props.handleReset}
              onSubmit={props.handleSubmit}
            >
              <FormRow
                css={css`
                  display: flex;
                `}
              >
                <div
                  css={css`
                    flex-grow: 1;
                  `}
                >
                  <TextInput
                    disabled={props.isSubmitting}
                    error={props.errors.FirstName}
                    id={"FirstName"}
                    maxLength={fields["FirstName"].maxLength}
                    name={"FirstName"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    placeholder={fields["FirstName"].label}
                    required={fields["FirstName"].required}
                    value={props.values.FirstName}
                  />
                </div>
                <div
                  css={css`
                    flex-grow: 1;
                  `}
                >
                  <TextInput
                    disabled={props.isSubmitting}
                    error={props.errors.LastName}
                    id={"LastName"}
                    maxLength={fields["LastName"].maxLength}
                    name={"LastName"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    placeholder={fields["LastName"].label}
                    required={fields["LastName"].required}
                    value={props.values.LastName}
                  />
                </div>
              </FormRow>

              <FormRow>
                <TextInput
                  disabled={props.isSubmitting}
                  error={props.errors.Email}
                  id={"Email"}
                  maxLength={fields["Email"].maxLength}
                  name={"Email"}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  placeholder={fields["Email"].label}
                  required={fields["Email"].required}
                  type={"email"}
                  value={props.values.Email}
                />
              </FormRow>

              <FormRow>
                <TextInput
                  disabled={props.isSubmitting}
                  error={props.errors.Company}
                  id={"Company"}
                  maxLength={fields["Company"].maxLength}
                  name={"Company"}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  placeholder={fields["Company"].label}
                  required={fields["Company"].required}
                  value={props.values.Company}
                />
              </FormRow>

              <FormRow>
                <Select
                  disabled={props.isSubmitting}
                  error={props.errors.Country}
                  id={"Country"}
                  name={"Country"}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  options={fields["Country"].fieldMetaData.values.filter(
                    // Remove Marketo placeholder option.
                    // We generate our own placeholder.
                    (option: any) => option.value,
                  )}
                  placeholder={fields["Country"].label}
                  required={fields["Country"].required}
                  value={props.values.Country}
                />
              </FormRow>

              {stateOptions.length ? (
                <FormRow>
                  <Select
                    disabled={props.isSubmitting}
                    error={props.errors.State}
                    id={"State"}
                    name={"State"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    options={stateOptions}
                    placeholder={fields["State"].label}
                    required={fields["State"].required}
                    value={props.values.State}
                  />
                </FormRow>
              ) : null}

              {postalCodeIsVisible ? (
                <FormRow>
                  <TextInput
                    disabled={props.isSubmitting}
                    error={props.errors.PostalCode}
                    id={"PostalCode"}
                    maxLength={fields["PostalCode"].maxLength}
                    name={"PostalCode"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    placeholder={fields["PostalCode"].label}
                    required={fields["PostalCode"].required}
                    value={props.values.PostalCode}
                  />
                </FormRow>
              ) : null}

              {cityOptions.length ? (
                <FormRow>
                  <Select
                    disabled={props.isSubmitting}
                    error={props.errors.City}
                    id={"City"}
                    name={"City"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    options={cityOptions}
                    placeholder={fields["City"].label}
                    required={fields["City"].required}
                    value={props.values.City}
                  />
                </FormRow>
              ) : null}

              {fields["tempIndustry"] ? (
                <FormRow>
                  <Select
                    disabled={props.isSubmitting}
                    error={props.errors.tempIndustry}
                    id={"tempIndustry"}
                    name={"tempIndustry"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    options={fields["tempIndustry"].fieldMetaData.values.filter(
                      // Remove Marketo placeholder option.
                      // We generate our own placeholder.
                      (option: any) => option.value,
                    )}
                    placeholder={fields["tempIndustry"].label}
                    required={fields["tempIndustry"].required}
                    value={props.values.tempIndustry}
                  />
                </FormRow>
              ) : null}

              {fields["Primary_Area_of_Research__c"] ? (
                <FormRow>
                  <Select
                    disabled={props.isSubmitting}
                    error={props.errors.Primary_Area_of_Research__c}
                    id={"Primary_Area_of_Research__c"}
                    name={"Primary_Area_of_Research__c"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    options={fields[
                      "Primary_Area_of_Research__c"
                    ].fieldMetaData.values.filter(
                      // Remove Marketo placeholder option.
                      // We generate our own placeholder.
                      (option: any) => option.value,
                    )}
                    placeholder={fields["Primary_Area_of_Research__c"].label}
                    required={fields["Primary_Area_of_Research__c"].required}
                    value={props.values.Primary_Area_of_Research__c}
                  />
                </FormRow>
              ) : null}

              {secondaryAreaOptions.length ? (
                <FormRow>
                  <Select
                    disabled={props.isSubmitting}
                    error={props.errors.State}
                    id={"tempAreaofResearch"}
                    name={"tempAreaofResearch"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    options={secondaryAreaOptions}
                    placeholder={fields["tempAreaofResearch"].label}
                    required={fields["tempAreaofResearch"].required}
                    value={props.values.tempAreaofResearch}
                  />
                </FormRow>
              ) : null}

              {otherResearchAreaIsVisible ? (
                <FormRow>
                  <TextInput
                    disabled={props.isSubmitting}
                    error={props.errors.Other_Primary_Area_of_Research__c}
                    id={"Other_Primary_Area_of_Research__c"}
                    maxLength={
                      fields["Other_Primary_Area_of_Research__c"].maxLength
                    }
                    name={"Other_Primary_Area_of_Research__c"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    placeholder={
                      fields["Other_Primary_Area_of_Research__c"].label
                    }
                    required={
                      fields["Other_Primary_Area_of_Research__c"].required
                    }
                    value={props.values.Other_Primary_Area_of_Research__c}
                  />
                </FormRow>
              ) : null}

              {fields["formNotes"] ? (
                <FormRow>
                  <TextArea
                    disabled={props.isSubmitting}
                    id={"formNotes"}
                    maxLength={fields["formNotes"].maxLength}
                    name={"formNotes"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    placeholder={fields["formNotes"].label}
                    value={props.values.formNotes}
                  />
                </FormRow>
              ) : null}

              <FormRow>
                <RadioOrCheckBoxLabel error={props.errors.Subscribe__c}>
                  <CheckBox
                    checked={props.values?.Subscribe__c?.length}
                    disabled={props.isSubmitting}
                    error={props.errors.Subscribe__c}
                    id={"Subscribe__c"}
                    name={"Subscribe__c"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    value={fields["Subscribe__c"].fieldMetaData.values[0].value}
                  />
                  <Text as={"span"} size={"small"}>
                    {
                      "I would like to receive communications from 10x Genomics. "
                    }
                    <em>
                      {
                        "Note: you may change your email preferences at a later date."
                      }
                    </em>
                  </Text>
                </RadioOrCheckBoxLabel>
              </FormRow>

              <FormRow>
                <RadioOrCheckBoxLabel error={props.errors.collectionConsent}>
                  <CheckBox
                    checked={props.values?.collectionConsent?.length}
                    disabled={props.isSubmitting}
                    error={props.errors.collectionConsent}
                    id={"collectionConsent"}
                    name={"collectionConsent"}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    value={"yes"}
                  />
                  <Text as={"span"} size={"small"}>
                    {"I acknowledge and agree to the 10x website "}
                    <Anchor href={"/legal/terms-of-use/"} target={"_blank"}>
                      {"terms of use"}
                    </Anchor>
                    {" and "}
                    <Anchor href={"/legal/privacy-policy/"} target={"_blank"}>
                      {"privacy policy"}
                    </Anchor>
                    {"."}
                  </Text>
                </RadioOrCheckBoxLabel>
              </FormRow>

              {Object.keys(hiddenFields).map((key) => {
                return <Field key={key} name={key} type={"hidden"} />;
              })}

              <FormRow>
                <Button
                  disabled={props.isSubmitting}
                  id={submitID}
                  type={"submit"}
                >
                  <Text
                    as={"span"}
                    color={"white"}
                    css={css`
                      pointer-events: none;
                    `}
                    size={"medium"}
                    weight={"semibold"}
                  >
                    {"Submit"}
                  </Text>
                </Button>
              </FormRow>

              {submissionError ? (
                <FormRow>
                  <ErrorLabel error={submissionError} />
                </FormRow>
              ) : null}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default MarketoForm;
