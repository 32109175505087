import type { TextSize } from "@10xdev/types";
import { colorBlueMedium, colorNeonGreen } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import type { BackgroundMode } from "./types";
import { getParsedHTML } from "./utils";

interface Props {
  children: string | JSX.Element;

  mode: BackgroundMode;

  size?: TextSize;
}

/**
 * XXXL heading text for major impact.
 */
const Headline: FunctionComponent<Props> = ({
  size = "xxxlarge",
  mode,
  children,
}) => {
  const color = mode === "dark" ? "white" : "base";
  const emphasisColor = mode === "dark" ? colorNeonGreen : colorBlueMedium;

  return (
    <Heading
      as={"h1"}
      color={color}
      css={css`
        > em {
          color: ${emphasisColor};
          font-style: normal;
        }
        margin-bottom: 1.5rem;
      `}
      responsive={true}
      size={size}
    >
      {typeof children === "string" ? getParsedHTML(children) : children}
    </Heading>
  );
};

export default Headline;
