import type { Locale } from "@10xdev/cms/types";
import { languages } from "@10xdev/site/utils/available-locales";
import { languageToCountryMap } from "@10xdev/site/utils/available-locales";
import { DISMISSED_LANGUAGE_SELECTOR_KEY } from "@10xdev/site/utils/cookie-keys";
import { colorWhite, mediaPhoneOnly } from "@10xdev/design-tokens/dist";
import { css } from "@emotion/react";
import ISO6391 from "iso-639-1";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useState } from "react";
import Cookies from "universal-cookie";

import Icon from "../../../Icon";
import Text from "../../../Text";
import { getLanguageCopy } from "./utils";

interface Props {
  countryCode: string;
  onClose: () => void;
}

const Select = dynamic(() => import("./Select"), {
  ssr: false,
});

type LanguageOptions = Record<Locale, { label: string; value: Locale }>;

const LANGUAGE_OPTIONS: LanguageOptions = {} as LanguageOptions;

languages.forEach((language) => {
  LANGUAGE_OPTIONS[languageToCountryMap[language] as Locale] = {
    label: ISO6391.getNativeName(language),
    value: languageToCountryMap[language] as Locale,
  };
});

const LanguageSelect: FunctionComponent<Props> = ({ countryCode, onClose }) => {
  const cookies = new Cookies();
  const nextLocale: Locale = cookies.get("NEXT_LOCALE");
  const [currentLanguage, setCurrentLanguage] = useState(
    nextLocale ? LANGUAGE_OPTIONS[nextLocale] : LANGUAGE_OPTIONS["en"],
  );

  const { enCopy, foreignCopy } = getLanguageCopy(countryCode);

  const router = useRouter();

  const handleLocaleChange = (country: Locale) => {
    router.push(`/`, undefined, { locale: country });
    // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
    // Set the selected language on the cookie so that the user is returned to it next time:
    const cookies = new Cookies();
    cookies.set("NEXT_LOCALE", country);
    setCurrentLanguage(LANGUAGE_OPTIONS[country]);
  };

  return (
    <div
      css={css`
        background: ${colorWhite};
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        padding: 1rem 1rem;
        width: 100vw;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          max-width: 1248px;
          position: relative;
          width: 100%;
        `}
      >
        <div
          css={css`
            align-items: center;
            display: flex;

            @media (max-width: ${mediaPhoneOnly}) {
              align-items: center;
              display: flex;
              flex-direction: column;
              height: 108px;
              justify-content: space-between;
            }
          `}
        >
          <div
            css={css`
              align-items: center;
              display: flex;
              margin-right: 1rem;
              width: 100%;

              @media (max-width: ${mediaPhoneOnly}) {
                align-items: center;
                display: flex;
                flex-direction: column;
                height: 60px;
                justify-content: space-between;
                margin-right: 0;
              }
            `}
          >
            <Text
              as={"div"}
              color={"steelDarkest"}
              css={css`
                margin-right: 0.5rem;
              `}
              size={"medium"}
              weight={"regular"}
            >
              {enCopy}
            </Text>
            <Text
              as={"div"}
              color={"steelDarkest"}
              size={"medium"}
              weight={"regular"}
            >
              {foreignCopy}
            </Text>
          </div>

          <div
            css={css`
              flex-shrink: 0;
              width: 148px;
            `}
          >
            <Select
              onChange={({ value }: { label: string; value: Locale }) => {
                handleLocaleChange(value);
              }}
              options={Object.values(LANGUAGE_OPTIONS)}
              placeholder={"English"}
              value={currentLanguage}
            />
          </div>
        </div>
        <button
          css={css`
            background: ${colorWhite};
            border: none;
            cursor: pointer;
            margin: 0;
            margin-left: auto;
            padding: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
          onClick={() => {
            onClose();
            const cookies = new Cookies();
            cookies.set(DISMISSED_LANGUAGE_SELECTOR_KEY, true);
          }}
        >
          <Icon color={"base"} size={".75rem"} source={"close"} />
        </button>
      </div>
    </div>
  );
};

export default LanguageSelect;
