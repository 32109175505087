import {
  borderRadiusMedium,
  borderStandard,
  colorBlueMedium,
  colorSteelDarkest,
  colorSteelLight,
  colorSteelLightest,
  colorSteelMedium,
  fontFamilyBase,
  fontSizeMedium,
  fontWeightRegular,
  sizeMedium,
  sizeXxxlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ChangeEvent, FocusEvent, FunctionComponent } from "react";

interface Props {
  /** Activates or deactivates the input. */
  disabled?: boolean;

  /**
   * A unique identifier that enables the input to
   * be associated with a label for accessibility.
   */
  id?: string;

  /** The maximum number of characters permitted in the input. */
  maxLength?: number;

  /** A string used to identify this input during form submission. */
  name?: string;

  /** A callback to be invoked when the input loses focus. */
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;

  /** A callback to be invoked when the contents of the input change. */
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;

  /** Placeholder text to display when the input is empty. */
  placeholder?: string;

  /** The current value of the input. */
  value?: string;
}

const TextArea: FunctionComponent<Props> = ({
  disabled,
  id,
  maxLength = 200,
  onBlur,
  onChange,
  value,
}) => {
  return (
    <textarea
      css={css`
        border: ${borderStandard};
        border-radius: ${borderRadiusMedium};
        box-sizing: border-box;
        color: ${colorSteelDarkest};
        font-family: ${fontFamilyBase};
        font-size: ${fontSizeMedium};
        font-weight: ${fontWeightRegular};
        min-height: ${sizeXxxlarge};
        padding: ${sizeMedium};
        resize: none;
        width: 100%;

        :disabled {
          background-color: ${colorSteelLightest};
          color: ${colorSteelMedium};
          pointer-events: none;
        }

        :focus {
          border-color: ${colorBlueMedium};
          outline: none;
        }

        ::placeholder {
          color: ${colorSteelLight};
          font-weight: ${fontWeightRegular};
        }

        :disabled::placeholder {
          color: ${colorSteelLight};
        }
      `}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={"How can we help you?"}
      value={value}
    />
  );
};

export default TextArea;
