import type { TextSize } from "@10xdev/types";
import { colorBlueMedium, colorNeonGreen } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import type { BackgroundMode } from "./types";

interface Props {
  /**
   * Specifies the underlying HTML element.
   *
   * This element should only ever be an h1 or h2.
   * If a Headline is present, that should be h1 and
   * the Title should be h2. Otherwise, Title should be h1.
   */
  as: "h1" | "h2";

  children: string | JSX.Element;

  mode: BackgroundMode;

  size?: TextSize;
}

/**
 * XXL heading text for impact, but not quite as much impact as Headline.
 */
const Title: FunctionComponent<Props> = ({
  as,
  children,
  mode,
  size = "xxlarge",
}) => {
  const color = mode === "dark" ? "white" : "base";
  const emphasisColor = mode === "dark" ? colorNeonGreen : colorBlueMedium;

  return (
    <Heading
      as={as}
      color={color}
      css={css`
        > em {
          color: ${emphasisColor};
          font-style: normal;
        }
      `}
      responsive={true}
      size={size}
    >
      {children}
    </Heading>
  );
};

export default Title;
