import type { TextSize } from "@10xdev/types";
import {
  colorBlueMedium,
  colorNeonGreen,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import CTAButton from "./CTAButton";
import Description from "./Description";
import Eyebrow from "./Eyebrow";
import Footnote from "./Footnote";
import Headline from "./Headline";
import Title from "./Title";
import type { BackgroundMode, CTAButton as CTA } from "./types";
import VideoButton from "./VideoButton";

interface Props {
  /** A call-to-action definition. */
  action?: any;

  ctaButtons?: CTA[];

  /** Paragraph text. */
  description?: string;

  /** Properties for the eyebrow. */
  eyebrow?: {
    href?: string;
    text?: string;
  };

  /** An optional message below the CTA. */
  footnote?: string;

  /** Adds a flexbox column-gap between Copy and Hero **/
  gap?: number;

  /** Very large text. */
  headline?: string | JSX.Element;

  headlineSize?: TextSize;

  /**
   * Specifies white text on a blue background (dark mode)
   * or blue text on a light gray background (light mode)
   */
  mode: BackgroundMode;

  /** Specifies hero image overflow */
  overflow?: boolean;

  /** An optional slash element next to headline . */
  slash?: boolean;

  title?: string | JSX.Element;

  titleSize?: TextSize;

  /** An optional video item. */
  video?: any;

  /** Calculated width of Copy based on Hero width. */
  width?: number;
}

const markerColors = {
  dark: colorNeonGreen,
  light: colorBlueMedium,
};

/**
 * A layout container for textual copy and CTA buttons.
 */
const Copy: FunctionComponent<Props> = ({
  action,
  ctaButtons = [],
  description,
  eyebrow,
  gap,
  footnote,
  headline,
  headlineSize,
  mode,
  slash,
  title,
  titleSize,
  video,
  width,
}) => {
  const shouldRender =
    action || description || eyebrow || headline || title || video;

  // The title should render as an h1, unless the
  // larger headline is present, in which case it
  // should render as an h2.
  const titleElement = headline ? "h2" : "h1";

  return shouldRender ? (
    <div
      css={css`
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding-right: ${gap}%;
        white-space: pre-wrap;
        width: ${width}%;

        ${slash
          ? `::before {
          border-top: 1px solid ${mode && markerColors[mode]};
          content: "";
          position: absolute;
          transform: translate(-48px, 16px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }`
          : null}

        @media (max-width: ${mediaTabletLandscape}) {
          align-items: center;
          justify-content: center;
          padding: 0 0 24px 0;
          width: 100%;

          > * {
            text-align: center;
          }

          ::before {
            border-top: none;
          }
        }
      `}
    >
      {eyebrow ? (
        <Eyebrow href={eyebrow.href} mode={mode} text={eyebrow.text} />
      ) : null}

      {headline ? (
        <Headline mode={mode} size={headlineSize}>
          {headline}
        </Headline>
      ) : null}

      {title ? (
        <Title as={titleElement} mode={mode} size={titleSize}>
          {title}
        </Title>
      ) : null}

      {description ? <Description mode={mode} text={description} /> : null}

      {action || ctaButtons || video ? (
        <div
          css={css`
            align-items: center;
            column-gap: ${ctaButtons?.length > 1 ? "2rem" : "1rem"};
            display: flex;
            flex-wrap: wrap;
            ${footnote ? "margin-bottom: 2rem;" : null}
            row-gap: 1.5rem;

            @media (max-width: ${mediaTabletLandscape}) {
              align-items: center;
              flex-direction: column;
              justify-content: center;
              row-gap: 1rem;
            }
          `}
        >
          {ctaButtons?.length
            ? ctaButtons.map((button) => {
                const { href, id, icon, label, mobile, size, type } = button;
                return href ? (
                  <CTAButton
                    href={href}
                    icon={icon}
                    id={id}
                    key={href}
                    label={label}
                    mobile={mobile}
                    mode={mode}
                    size={size}
                    type={type}
                  />
                ) : null;
              })
            : null}

          {action && action.label && action.url ? (
            <CTAButton
              /* This class name is for tracking. Do not change plz. */
              className={"cta-primary"}
              href={action.url}
              /* This ID is for tracking. Do not change plz. */
              id={"cta-primary-hero"}
              label={action.label}
              mode={mode}
              size={{ height: "72px", width: "240px" }}
              type={"primary"}
            />
          ) : null}

          {video && video.label ? (
            <VideoButton
              /* This class name is for tracking. Do not change plz. */
              className={"cta-secondary"}
              css={css`
                flex: 1 1 auto;
                justify-content: left;
                width: auto;
              `}
              /* This ID is for tracking. Do not change plz. */
              id={"cta-secondary-hero"}
              mode={mode}
              video={video}
            />
          ) : null}
        </div>
      ) : null}

      {footnote ? <Footnote mode={mode}>{footnote}</Footnote> : null}
    </div>
  ) : null;
};

export default Copy;
