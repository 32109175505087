import { colorBlueMedium, layoutWidth720 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { MDXRemoteSerializeResult } from "next-mdx-remote";
import { MDXRemote } from "next-mdx-remote";
import type { FunctionComponent } from "react";

import Heading from "../../Heading";
import MDXComponents from "./MDXComponents";

interface Props {
  contentWidth: string;
  displayMarker: boolean;
  isPreview?: boolean;
  mdx: MDXRemoteSerializeResult;
  padding?: string;
  title: string;
}

const MDX: FunctionComponent<Props> = ({
  contentWidth,
  displayMarker,
  mdx,
  padding,
  title,
}) => {
  return (
    <div
      className={"MDX"}
      css={css`
        max-width: ${contentWidth || layoutWidth720};
        overflow: hidden;
        padding: ${padding || "72px 24px"};
        width: 100%;

        ::before {
          border-top: ${displayMarker ? 1 : 0}px solid ${colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 24px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }
      `}
    >
      {title ? (
        <Heading
          as={"h2"}
          css={css`
            margin-bottom: 3rem;
          `}
          size={"xxlarge"}
        >
          {title}
        </Heading>
      ) : null}

      <MDXRemote {...mdx} components={MDXComponents} />
    </div>
  );
};

export default MDX;
