import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Paragraph from "../../Paragraph";

interface Props {
  /** Specifies white text (dark mode) or blue text (light mode). */
  mode?: "light" | "dark";

  /** The text to display */
  text?: string;
}

/**
 * Large description text, for longer copy.
 */
const Description: FunctionComponent<Props> = ({ mode, text }) => {
  const color = mode === "dark" ? "white" : "midgray";
  return (
    <Paragraph
      color={color}
      css={css`
        margin-bottom: 2rem;
      `}
      size={"medium"}
    >
      {text}
    </Paragraph>
  );
};

export default Description;
