import { mediaTabletLandscape, sizeXxxlarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useState } from "react";

import Button from "../../Button";
import Icon from "../../Icon";
import Text from "../../Text";
import WistiaPopover from "../../WistiaPopover";
import type { ButtonType } from "./types";

interface Props {
  /**
   * A CSS class name. This has no effect on styling;
   * it is used to identify the button to Google Analytics.
   * */
  className?: string;

  /**
   * A unique identifier. Useful for identifying
   * the button to Google Analytics or other services.
   * */
  id?: string;

  /**
   * Specifies white text on a blue background (light mode)
   * or blue text on a white background (light mode). */
  mode?: "light" | "dark";

  /**
   * A video definition.
   */
  video?: any;
}

/**
 * A button that invokes a Wistia video modal when clicked.
 */
const VideoButton = ({ className, id, mode, video }: Props) => {
  const [showVideo, setShowVideo] = useState(false);
  const { label, mobile, type = "primary", wistiaID } = video || {};

  const handleClick = () => setShowVideo(true);
  const handleClose = () => setShowVideo(false);

  const primaryButton = css`
    column-gap: 0.75rem;
    height: ${sizeXxxlarge};
  `;

  const secondaryButton = css`
    column-gap: 0.5rem;
    height: auto;
  `;

  const MobileStyles = css`
    @media (max-width: ${mediaTabletLandscape}) {
      display: none;
    }
  `;

  const getButtonStyles = (type: ButtonType) => {
    const styles = {
      ["primary"]: primaryButton,
      ["secondary"]: secondaryButton,
    };
    return styles[type];
  };

  const modeColor = mode === "light" ? "blue" : "white";

  return (
    <Button
      background={"transparent"}
      className={className}
      css={css`
        ${mobile?.hide ? MobileStyles : null}
        ${getButtonStyles(type)}
      `}
      id={id}
      onClick={handleClick}
    >
      {type === "secondary" ? (
        <Icon
          color={modeColor}
          size={"20px"}
          source={"play-circle"}
          yPos={"1px"}
        />
      ) : null}
      <Text
        as={"span"}
        color={modeColor}
        size={type === "primary" ? "medium" : "small"}
        weight={type === "primary" ? "semibold" : "regular"}
      >
        {label}
      </Text>
      {type === "primary" ? (
        <Icon color={modeColor} size={"12px"} source={"play"} yPos={"2px"} />
      ) : null}

      {showVideo ? <WistiaPopover id={wistiaID} onClose={handleClose} /> : null}
    </Button>
  );
};

export default VideoButton;
