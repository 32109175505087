import ISO31661 from "iso-3166-1";

export const getLanguageCopy = (
  countryCode: string,
): { enCopy: string; foreignCopy: string } => {
  const languageCopy =
    countryCode === ISO31661.whereCountry("Japan")?.alpha2
      ? {
          enCopy: "Choose another language",
          foreignCopy: "別の言語を選択してください",
        }
      : {
          enCopy: "Choose another language",
          foreignCopy: "切换至另一种语言",
        };

  return languageCopy;
};
