import {
  colorBlueMedium,
  colorNeonGreen,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";

interface Props {
  /** Optional link for the eyebrow text */
  href?: string;

  /** Specifies neon green text (dark mode) or blue text (light mode). */
  mode?: "light" | "dark";

  /** The text to display */
  text?: string;
}

/**
 * An eyebrow component that provides label text.
 */
const Eyebrow: FunctionComponent<Props> = ({ href, mode, text }) => {
  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        gap: 0.75rem;
        margin-bottom: 1.5rem;

        @media (max-width: ${mediaTabletLandscape}) {
          gap: 0.5rem;
          justify-content: center;

          /*
          The standard responsive sizing doesn't cut it for this case.
          The text is still too large on some mobile screens.
          */
          > h1 {
            font-size: 1.125rem;
          }
        }
      `}
    >
      <Heading
        as={"h1"}
        css={css`
          color: ${mode === "dark" ? colorNeonGreen : colorBlueMedium};
          margin-bottom: 0;
        `}
        size={"large"}
      >
        {href ? (
          <Anchor color={"inherit"} href={href} underlineOnHover={true}>
            {text}
          </Anchor>
        ) : (
          text
        )}
      </Heading>
    </div>
  );
};

export default Eyebrow;
