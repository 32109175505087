import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactElement } from "react";
import { useEffect, useRef, useState } from "react";

import Anchor from "../../Anchor";
import Text from "../../Text";

export interface HeroProps {
  alt?: string;
  content?: string;
  link?: string;
  overflow?: boolean;
  type?: string;
  url?: string;
}

interface Props {
  /** Overflow image height, determined by height of parent */
  height?: number;

  /** A hero definition, either image or text. */
  hero: HeroProps;

  /** Specifies white text (dark mode) or blue text (light mode). */
  mode?: "light" | "dark";

  /** Specifies width (percent) of hero image. */
  width?: number;
}

type WrapperProps = {
  children: ReactElement;
  link?: string;
  wrap: (children: ReactElement, link: string) => JSX.Element;
};

/**
 A component that returns its children wrapped in an
 Anchor if hero.link is used.
 */
const LinkWrap: FunctionComponent<WrapperProps> = ({
  children,
  link,
  wrap,
}) => {
  return link ? wrap(children, link) : <>{children}</>;
};

/**
 * A layout container for hero text or a hero image.
 */
const Hero: FunctionComponent<Props> = ({ height, hero, mode, width }) => {
  const { alt, content, link, overflow, type, url } = hero || {};

  const color = mode === "dark" ? "white" : "base";

  const [alignItems, setAlignItems] = useState("flex-start");
  const heroRef = useRef<HTMLDivElement>(null);
  const heroImageRef = useRef<HTMLImageElement>(null);

  /*
  If a hero image is wider than the container, it should align left.
  It a hero image is narrower than the container, it should align to center.
  (If CSS rules are coming from the mediaTabletLandscape media query,
  then alignment should always be to center, unaffected by these measurements.)
  */
  const getAlignment = () => {
    const { offsetWidth: containerWidth } = heroRef?.current || {};

    const { clientWidth: imageOffsetWidth } = heroImageRef?.current || {};

    if (
      typeof imageOffsetWidth === "number" &&
      typeof containerWidth === "number" &&
      imageOffsetWidth < containerWidth
    ) {
      setAlignItems("center");
    } else {
      setAlignItems("flex-start");
    }
  };

  // Ensure the image aligns when rendered statically (Next.js)
  useEffect(() => {
    if (type === "image" || type === "gif") {
      getAlignment();
    }
  }, [type]);

  // Ensure the image aligns when rendered dynamically (Storybook)
  const handleLoad = () => getAlignment();

  return hero ? (
    <div
      css={css`
        align-items: ${overflow ? "flex-start" : alignItems};
        align-self: stretch;
        display: flex;
        flex-direction: column;
        position: ${overflow ? "static" : "relative"};
        width: ${width}%;

        @media (max-width: ${mediaTabletLandscape}) {
          align-items: center;
          display: none;
          width: 100%;
        }
      `}
      ref={heroRef}
    >
      {type === "image" ? (
        <LinkWrap
          link={link}
          wrap={(children, wrapperLink) => (
            <Anchor href={wrapperLink} target={"_blank"}>
              {children}
            </Anchor>
          )}
        >
          <img
            alt={alt}
            css={css`
              margin-top: -32px;
              max-height: ${overflow ? height + "px" : "700px"};
              position: ${link ? "static" : "absolute"};

              @media (max-width: ${mediaTabletLandscape}) {
                margin-top: 0;
                max-height: auto;
                max-width: 100%;
                position: relative;
              }
            `}
            onLoad={handleLoad}
            ref={heroImageRef}
            src={url}
          />
        </LinkWrap>
      ) : null}

      {type === "gif" ? (
        <>
          <video
            autoPlay
            css={css`
              height: auto;
              width: 100%;
            `}
            loop
            muted
            playsInline
          >
            <source src={url} type={"video/mp4"} />
          </video>
          <div
            css={css`background: linear-gradient(
                rgb(18, 18, 18, 1) 0%,
                rgba(18, 18, 18, 0) 15%, 
                rgba(18, 18, 18, 0) 100%
               ),
               linear-gradient(
                rgba(18, 18, 18, 0) 0%,
                rgba(18, 18, 18, 0) 85%, 
                rgba(18, 18, 18, 1) 100%
               );
              linear-gradient(hsla(0, 0%, 0%, 1), transparent);
              position: absolute;
              z-index: 4;
              height: 100%;
              width: 100%;`}
          ></div>
        </>
      ) : null}

      {type === "text" ? (
        <Text as={"p"} color={color} size={"large"}>
          {content}
        </Text>
      ) : null}
    </div>
  ) : null;
};

export default Hero;
