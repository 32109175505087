import { Fragment } from "react";

/**
 * Accepts a string containing <em> tags, entered via Charlie.
 * The text between the <em> tags should be colorized for emphasis.
 *
 * Returns an array of React children, where the <em>-wrapped
 * substrings are converted to colorized spans.
 */
export const getParsedHTML = (html: string) => {
  const nodes = html
    // Split string on opening HTML tags
    .split(/<[^/].+?(?=>)/)

    // Split again on closing HTML tags
    .flatMap((item) => {
      if (item.startsWith(">")) {
        return item.split(/<\/.+?>/);
      }
      return item;
    })

    // Remove falsy indexes
    .filter(Boolean)

    // Convert formerly-wrapped strings to React ems
    .map((item) => {
      // Colorized
      if (item.startsWith(">")) {
        const children = item.replace(">", "");
        const Element = "em";
        return <Element key={item}>{children}</Element>;
      }

      // Not colorized
      return <Fragment key={item}>{item}</Fragment>;
    });

  return <>{nodes}</>;
};
