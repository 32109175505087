import type { FunctionComponent } from "react";

import Text from "../../Text";

interface Props {
  /** The text to display */
  children?: string;

  /** Specifies white text (dark mode) or blue text (light mode). */
  mode?: "light" | "dark";
}

/**
 * Footnote for legal or subtext. Sits below CTAs.
 */
const Footnote: FunctionComponent<Props> = ({ mode, children }) => {
  const color = mode === "dark" ? "white" : "midgray";
  return (
    <Text as={"div"} color={color} size={"xxsmall"}>
      {children}
    </Text>
  );
};

export default Footnote;
