import { sizeMedium, sizeXxxlarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

interface Props {
  children?: ReactNode;
  className?: string;
}

const FormRow: FunctionComponent<Props> = ({ children, className }) => {
  return (
    <div
      className={className}
      css={css`
        margin-bottom: ${sizeMedium};
        width: 100%;

        > *:not(:last-of-type) {
          margin-right: ${sizeMedium};
        }

        :last-of-type {
          padding-bottom: ${sizeXxxlarge};
        }
      `}
    >
      {children}
    </div>
  );
};

export default FormRow;
